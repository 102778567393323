import React, { FC, useState, useEffect } from 'react'
import useWindowSize from '@hooks/useWindowSize'
import StoryComponent from './Story'
import { StaticQuery, graphql } from 'gatsby'

interface Story {
  node: {
    frontmatter: {
      date: string
    }
  }
  id?: number
  title?: string
  author?: string
  function?: string
  slug?: string
  images: {
    portrait: string
    landscape: string
    overlay: boolean
  }
  cta: string
}

type T = {
  allStories: Story[]
}

const StoriesComponent: FC<T> = ({ allStories }) => {
  const [width, height] = useWindowSize()
  const [storiesSorted, setStoriesSorted] = useState<Story[]>([])
  const [stories, setStories] = useState<Story[] | {}[]>([{}])
  const [amount, setAmount] = useState<number>(3)
  const [offset, setOffset] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pagination, setPagination] = useState<number>(0)
  const [paginationVisible, setPaginationVisible] = useState<number[]>([])
  const storiesLeft = storiesSorted.length - (offset + stories.length)

  // Next
  const loadNext = (): void => {
    if (currentPage === pagination) return
    loadPage(currentPage + 1)
  }

  // Prev
  const loadPrev = (): void => {
    if (currentPage - 1 < 0) return
    loadPage(currentPage - 1)
    // const newOffset = offset - stories.length < 1 ? 0 : offset - stories.length
    // setOffset(newOffset)
  }

  // Last
  const loadLast = (): void => {
    if (storiesLeft === 0) return
    loadPage(pagination)
  }

  // page
  const loadPage = (pageNumber: number) => {
    const nLeft = storiesSorted.length - (pageNumber - 1) * stories.length
    let newOffset = (pageNumber - 1) * stories.length
    if (newOffset < 0) newOffset = 0

    // We dont want to overreach in the stories array, if there are less left than the amount in view, only change by that amount.
    if (nLeft < stories.length && pageNumber > currentPage) {
      newOffset += nLeft - stories.length
    }
    setCurrentPage(pageNumber)
    setOffset(newOffset)
    setStories(storiesSorted.slice(newOffset, newOffset + amount))
  }

  const parseDate = (input: string, format?: string) => {
    format = format || 'yyyy-mm-dd' // default format
    var parts = input.match(/(\d+)/g),
      i = 0,
      fmt = {}
    // extract date-part indexes from the format
    format.replace(/(yyyy|dd|mm)/g, function(part) {
      fmt[part] = i++
    })

    return new Date(parts[fmt['yyyy']], parts[fmt['mm']] - 1, parts[fmt['dd']])
  }

  useEffect(() => {
    loadPage(1)
  }, [amount])

  // Sort stories on date
  useEffect(() => {
    console.log(allStories)

    const sortedStories = allStories.sort(function(a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return (
        parseDate(b.node.frontmatter.date, 'dd.mm.yyyy') -
        parseDate(a.node.frontmatter.date, 'dd.mm.yyyy')
      )
    })
    setStoriesSorted(sortedStories)
  }, [allStories])
  // Set stories on load and window width change
  useEffect(() => {
    let n = width < 600 ? 2 : 3
    if (width < 813 && width > 2 * window.innerHeight) n = 2
    setAmount(n)
  }, [width])

  // Pagination
  useEffect(() => {
    setPagination(Math.ceil(allStories.length / stories.length))
  }, [stories])

  // Pagination Visible
  useEffect(() => {
    const newBegin = currentPage - 1
    let begin = newBegin
    if (newBegin <= 1) begin = 1

    const end = storiesLeft <= 0 ? null : begin + 1

    const arr =
      end === null ? [begin - 1, begin, begin + 1] : [begin, begin + 1, end + 1]

    setPaginationVisible(arr)
  }, [currentPage, storiesSorted])

  return (
    <div>
      <div className="stories">
        <div className="story__col full">
          {stories[0] && <StoryComponent size="full" story={stories[0]} />}
        </div>

        <div className="story__col column">
          {stories[1] && (
            <StoryComponent
              size={stories.length === 2 ? 'full' : 'half'}
              story={stories[1]}
            />
          )}

          {/* <template v-if="stories.length > 2"> */}
          {stories.length > 2 && (
            <div className="story__col--small">
              {/* <!-- 3rd --> */}
              {stories[2] && (
                <StoryComponent
                  size={stories.length === 3 ? 'half' : 'quarter'}
                  story={stories[2]}
                />
              )}

              {/* <!-- 4th --> */}
              {stories[3] && (
                <StoryComponent size="quarter" story={stories[3]} />
              )}
            </div>
          )}
          {/* </template> */}
        </div>
      </div>
      <div className="pagination">
        <button
          title="Eerste pagina"
          className="pagination__nav"
          onClick={() => loadPage(0)}
        >
          {'<<'}
        </button>
        <button
          title="Eerste pagina"
          className="pagination__nav"
          onClick={() => loadPrev()}
        >
          {'<'}
        </button>
        {paginationVisible.map((page: number) => (
          <button
            key={page}
            // ariaSelected={"page === currentPageNumber ? 'true' : 'false'"
            title={`Pagina nummer ${page}`}
            className={
              'pagination__item' + (page === currentPage ? ' active' : '')
            }
            onClick={() => loadPage(page)}
          >
            {page}
          </button>
        ))}
        <button
          title="Laatste pagina"
          className="pagination__nav"
          onClick={() => loadNext()}
        >
          {'>'}
        </button>
        <button
          title="Laatste pagina"
          className="pagination__nav"
          onClick={() => loadLast()}
        >
          {'>>'}
        </button>
      </div>
    </div>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      query allStories {
        allMdx {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                title
                outboundUrl
                images {
                  portrait
                  landscape
                }
                author {
                  name
                  function
                }
                date
              }
            }
          }
        }
      }
    `}
    render={data => <StoriesComponent allStories={data.allMdx.edges} />}
  />
)
