import React, { FC, useState, useEffect } from 'react'

import { Link } from 'gatsby'

// interface Story {
//   id: number
//   title: string
//   author: string
//   function: string
//   slug: string
//   image: string
// }

interface Data {
  fields: {
    slug: string
    outboundUrl: string
  }
  frontmatter: {
    outboundUrl: string
    title: string
    images: {
      portrait: string
      landscape: string
    }
    date: string
    author: {
      name: string
      function: string
    }
  }
}

interface StoryProps {
  story: {
    node: Data
  }
  size: 'full' | 'quarter' | 'half'
}

const data = {
  fields: {
    slug: '',
    outboundUrl: '',
  },
  frontmatter: {
    outboundUrl: '',
    title: '',
    images: {
      landscape: '',
      portrait: '',
    },
    date: '',
    author: {
      name: '',
      function: '',
    },
  },
}

const StoryComponent: FC<StoryProps> = ({ story, size }) => {
  const [{ fields, frontmatter }, setData] = useState<Data>(data)
  const [hasQuotes, setHasQuotes] = useState<boolean>(false)
  const [formattedTitle, setFormattedTitle] = useState(frontmatter.title)
  useEffect(() => {
    setHasQuotes(frontmatter.title.startsWith('“'))
    setFormattedTitle(frontmatter.title.replace(/“|”/g, ''))
  }, [frontmatter.title])

  useEffect(() => {
    if (story.node) {
      setData(story.node)
    }
  }, [story.node])
  return (
    <div className="story-wrapper">
      <div
        key="story.id"
        className={`story ${size}`}
        style={{
          backgroundImage: `url(${
            frontmatter.images
              ? `../../${frontmatter.images.landscape}`
              : 'assets/images/twerken.jpg'
          })`,
        }}
      >
        <div className="story-content">
          <blockquote className={hasQuotes ? 'blockquote-quotes' : ''}>
            {formattedTitle}
          </blockquote>
          <div className="person">
            <strong>{frontmatter.author && frontmatter.author.name}</strong>
            <br />
            {frontmatter.outboundUrl ? (
              <span>{frontmatter.date}</span>
            ) : (
              <span>{frontmatter.author && frontmatter.author.function}</span>
            )}
          </div>

          {frontmatter.outboundUrl ? (
            <a
              href={frontmatter.outboundUrl}
              role="button"
              target="_blank"
              rel="noopener noreferrer"
              title={`Lees het artikel van ${frontmatter.author.name}`}
              className="button"
            >
              <span className="button__text">Lees verder</span>
              <svg
                className="icon"
                width="26px"
                height="16px"
                viewBox="0 0 68 51"
              >
                <use xlinkHref="#svg-arrow" />
              </svg>
            </a>
          ) : (
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="button"
              href={`/verhalen${fields.slug}`}
            >
              <span title={`Lees het artikel van ${frontmatter.author.name}`}>
                <span className="button__text">Lees verder</span>
                <svg
                  className="icon"
                  width="26px"
                  height="16px"
                  viewBox="0 0 68 51"
                >
                  <use xlinkHref="#svg-arrow" />
                </svg>
              </span>
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

export default StoryComponent
